.colorpicker {
  position: relative;
  // width: 14em;
  height: 13em;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 0.25em;
  box-shadow: 0 3px 6px rgba(0,0,0,0.3);
}

.map {
  position: absolute;
  top: 1em;
  bottom: 1em;
  right: 2em;
  left: 1em;
  overflow: hidden;
  user-select: none;
  border-radius: 0.25em;

  &.active {
    cursor: none;
  }

  &.dark .pointer {
    border-color: #fff;
  }

  &.light .pointer {
    border-color: #000;
  }

  .pointer {
    position: absolute;
    width: 10px;
    height: 10px;
    margin-left: -5px;
    margin-bottom: -5px;
    border-radius: 100%;
    border: 1px solid #000;
    will-change: left, bottom;
  }

  .background {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .background:before,
  .background:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .background:after {
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
  }

  .background:before {
    background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
  }

}

.slider {
  position: absolute;
  user-select: none;

  &.vertical {
    top: 0;
    bottom: 0;
    left: 50%;
    width: 10px;
    cursor: ns-resize;

    .track {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      width: 8px;
      margin-left: -4px;
    }
  }

  &.horizontal {
    left: 0;
    right: 0;
    top: 50%;
    height: 10px;
    cursor: ew-resize;

    .track {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      height: 8px;
      margin-top: -4px;
    }
  }

  .track {
    border-radius: 3px;
    background: #888;
  }

  .pointer {
    position: absolute;
    bottom: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    margin-left: -8px;
    margin-bottom: -8px;
    border-radius: 16px;
    background: #fff;
    box-shadow: inset 0 0 0 1px #ccc,0 1px 2px #ccc;
    will-change: left, bottom;
  }

}

.hue-slider {
  position: absolute;
  top: 1em;
  bottom: 1em;
  right: 1.3em;

  .track {
    background: linear-gradient(to bottom,
      #FF0000 0%,
      #FF0099 10%,
      #CD00FF 20%,
      #3200FF 30%,
      #0066FF 40%,
      #00FFFD 50%,
      #00FF66 60%,
      #35FF00 70%,
      #CDFF00 80%,
      #FF9900 90%,
      #FF0000 100%
    );
  }

}

.opacity-slider {
  position: absolute;
  bottom: 1.3em;
  left: 1em;
  right: 2em;

  .slider {
    background: #fff url('data:image/gif;base64,R0lGODdhEAAQAPEAAMvLy8zMzP///wAAACwAAAAAEAAQAEACHYxvosstCAEMrq6Jj812Y59NIDQipdY5XLWqH4sVADs=') repeat;
    background-size: 8px 8px;
    height: 8px;

    .track {
      height: 8px;
      margin-top: -4px;
      background: linear-gradient(to right,
        rgba(255,255,255,0) 0%,
        #FFF 100%
      );
    }
  }

}

.with-opacity-slider {
  // height: 14em;
  margin-right:20px;
  margin-left:20px;
  .map {
    bottom: 2em;
  }

  .hue-slider {
    bottom: 2em;
  }
}

body {
  background: #eee;
  font-size: 20px;
}

#container {
  position: absolute;
  top: 20%;
  left: 10%;
  bottom: 20%;
  right: 10%;
  box-shadow: 0 0 30px rgba(0,0,0,0.3);
}


@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.picker {
  position: relative;
  margin-bottom: 1em;
  @include clearfix;
}

.picker-right {
  // height: 20em;
}

@media (min-width:50em) {
  .picker {
    // width: 50%;
    // float: left;
  }
}

.swatch-item {
  width: 20px;
  height: 20px;
  float: left;
  border: none;
  -webkit-transition: -webkit-transform 0.1s;
  transition: transform 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  position: relative;
  outline: none;

  &:focus {
    -webkit-transform : scale(1.05);
    transform : scale(1.05);
    z-index: 5;
    border-radius: 3px;
  }

  &.swatch-selected {
    -webkit-transform : scale(1.1) !important;
    transform : scale(1.1) !important;
    box-shadow: 0 2px 3px rgba(0,0,0,0.3);
    border-radius: 3px;
    z-index: 10 !important;
  }
}