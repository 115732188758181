.table th, .table td {
    padding-top: 0.15rem;
    padding-bottom: 0.15rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    vertical-align: middle;
    white-space: normal!important;
}

/* .table th{
    overflow: auto;
    display: block;
    table-layout: auto;
    } */

.table {table-layout: 'fixed';}


th {
    white-space: normal!important;
}
