body {
    background: #fff;
    font-family: sans-serif;
    font-size: 12px;
    overflow: auto;
    /* padding: 12px; */
}
#target, #source {
    background: #ddd;
    float: left;

}


#targetFields { margin-bottom: 6px; }
.sourceField, .targetField { margin: 6px; }
.sourceField {
    border-radius: 4px;
    box-shadow: 1px 1px 1px rgba(0,0,0,0.25);
    font-size: 11px;
    /* margin: 6px; */
    /* padding: 2px 6px; */
    width: 100px;
    z-index: 1000;
}
/* .sourceField:hover { background: #ddd; border-color: #aaa; cursor: default; } */
.targetField {
    
    display: inline-block;
    font-size: small;
    height: 24px;
    line-height: 24px;
    /* margin: 6px 12px; */
    vertical-align: middle;
    /* padding: 0 3px; */
    width: 120px;
    background-color: lightgrey;
    border: 2px solid transparent;
}
/* .targetField .k-icon:hover {
    cursor: pointer;
} */
#output {
    clear: left;
    display: none;
    height: 200px;
    margin: 12px;
    width: 500px;
}

.k-widget {
    color:transparent;
    background-color: transparent;
}

.k-cancel{
    color: purple
}

.k-state-active {
    color: purple
}

.k-icon {
    color: purple;
    width: 20px;
    height: 20px;
    background-color: purple;
}

.delete {
    float: right;
    font-size: 1px;
}


.targetButton {
    width:100%;
}

.draggableButton {
    width:100%;
}

.targetLabel {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width:100%;
}
