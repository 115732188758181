
.dataTableCopy {
    vertical-align: middle;
    color: #11cdef;
    border-radius: 5px;
    padding:2px;
  }
  
  .dataTableCopy:hover {
    background-color: #11cdef;
    color: white;
  }
  
  .dataTableRowUp {
    vertical-align: middle;
    color: #2dce89;
    border-radius: 5px;
    padding:2px;
  }
  
  .dataTableRowUp:hover {
    background-color:#2dce89;
    color: white;
  }
  
  .dataTableRowDown {
    vertical-align: middle;
    color: #2dce89;
    border-radius: 5px;
    padding:2px;
  }
  
  .dataTableRowDown:hover {
    background-color: #2dce89;
    color: white;
  }
  
  .dataTableDelete {
    vertical-align: middle;
    color: #f5365c;
    border-radius: 5px;
    padding:2px;
  }
  
  .dataTableDelete:hover {
    background-color:#f5365c;
    color: white;
  }
  
  .dataTableRowOptionsTooltip .dataTableRowOptionsTooltipText {
    visibility: hidden;
    position: absolute;
    font: 12px arial;
    color: white;
    margin-top: 22px;
    /* background-color: #00928b; */
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    padding: 5px;
  }
  
  .dataTableRowOptionsTooltip:hover .dataTableRowOptionsTooltipText {
    visibility: visible;
    max-height:initial;
  }
  
  .dataTableRowOptionsTooltip:hover:after {
    z-index: 50;
    display: block
  }
  
  .rowOptionsRenderer {
    display: inline-flex;
    z-index: 9999999;
  }
  
  .dataTableRowOptionsTooltipTextCopy {
    background-color:#11cdef;
  }
  
  .dataTableRowOptionsTooltipTextAdd {
    background-color:#2dce89;
  }
  
  .dataTableRowOptionsTooltipTextDelete {
    background-color:#f5365c;
  }
  
  .dataTableColorSelectorButton {
    height:10px;
    border: none;
    vertical-align: middle;
    width:calc(100% - 4px);
    padding-left:2px;
    padding-right:2px;
    border-radius:5px;
  }
  
  .dataTableRowStatusIconSuccess {
    background-color: white;
    color:#2dce89;
    border-radius: 5px;
    vertical-align: middle;
  }
  
  .dataTableRowStatusIconWarning {
    background-color: white;
    color:#fb6340;
    border-radius: 5px;
    vertical-align: middle;
  }
  
  .dataTableRowStatusIconError {
    background-color: white;
    color:#f5365c;
    border-radius: 5px;
    vertical-align: middle;
  }
  
  
  .dataTableRowStatusTooltip .dataTableRowStatusTooltipText {
    visibility: hidden;
    position: absolute;
    font: 12px arial;
    color: white;
    margin-top: 22px;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    padding: 5px;
    white-space:pre;
    line-height: 1rem;
  }
  
  .dataTableRowStatusTooltip:hover .dataTableRowStatusTooltipText {
    visibility: visible;
    background-color:#fb6340;
  }
  
  .cellCenter .ag-cell-wrapper {
    justify-content: center;
  }