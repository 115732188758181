.App {
    font-family: sans-serif;
    text-align: center;
  }

.helpIcon {
  float: left;
  display: block;
  font-size: 0.8rem !important;
}

.tooltip 
{
max-width: 250px;
}
  