.pac-container {
    z-index: 2001 !important;
    min-width: 500px;

}

.modal {
    z-index: 2000;
}

.modal-backdrop {
    z-index: 1000;
}