.gutter {
    /* background: rgba(255, 255, 255, 0.05); */
    background: lightsteelblue;
    flex-shrink: 0;
    margin-left:1px;
    margin-right:1px;
    /* border: "0 solid rgba(" + hexToRgb(themeColors.black.main) + ",.15)", */
    border-radius: 0.4375rem;
    border: 0px solid white;
}

.gutter:hover {
    background: slateblue;
}

  
.gutter-horizontal {
cursor: ew-resize;
z-index:5
}

.gutter .expanded {
    width: '10px';
}

.collapsed {
    width: 0px;
    background: lightgray;
    color:'inherit';
    cursor: inherit;
    z-index: -1;
}

  
.gutter-vertical {
cursor: ns-resize;
}